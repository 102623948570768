import {AppManifest} from '@wix/platform-editor-sdk';
import {AppManifestBuilder, EditorScriptFlowAPI, FlowEditorSDK} from '@wix/yoshi-flow-editor';

function enrichManifestWithGallerySettings(manifest: AppManifest, _flowAPI: EditorScriptFlowAPI) {
  // @ts-expect-error
  manifest.pages.pageSettings.Gallery = [
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.pageInfo.tabName'),
      type: 'page_info',
    },
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.layouts.tabName'),
      type: 'layout',
    },
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.permissions.tabName'),
      type: 'permissions',
    },
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.seo.tabName'),
      type: 'seo',
    },
  ];
}

function configureState(stateBuilder: any, _flowAPI: EditorScriptFlowAPI) {
  stateBuilder.set({
    icon: 'page',
    tooltip: _flowAPI.translations.t('NFT Gallery'),
    orderIndex: 2,
  });
  stateBuilder.addAction(
    {
      type: 'page_rename',
    },
    {
      type: 'page_set_as_homepage',
    },
    {
      type: 'page_delete',
    }
  );
}

function configurePagesTab(pagesTabBuilder: any, _flowAPI: EditorScriptFlowAPI, editorSDK: FlowEditorSDK) {
  pagesTabBuilder
    .set({
      displayName: _flowAPI.translations.t('pagesPanel.nftPages.tabName'),
      helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
    })
    .addAction(
      {
        title: _flowAPI.translations.t('pagesPanel.nftPages.manageItems.button'),
        icon: 'appManager_settingsAction',
        onClick: (event: any) => {
          editorSDK.editor.openDashboardPanel('', {url: 'crypto/nft', closeOtherPanels: false});
        },
      },
      {
        title: _flowAPI.translations.t('pagesPanel.nftPages.delete.button'),
        icon: 'deleteRadio',
        onClick: (event: any) => {
          editorSDK.application.uninstall('', {openConfirmation: true});
        },
      }
    )
    .setGrouping('routers');
}

function configurePages(pagesBuilder: any, _flowAPI: EditorScriptFlowAPI) {
  // specific page actions according to page state
  pagesBuilder.configureState('Gallery', (stateBuilder: any) => configureState(stateBuilder, _flowAPI));
  pagesBuilder.addSettingsTab(
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.pageInfo.tabName'),
      type: 'page_info',
    },
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.layouts.tabName'),
      type: 'layout',
    },
    {
      title: _flowAPI.translations.t('pagesPanel.nftPages.permissions.tabName'),
      type: 'permissions',
    }
  );
}

function configureWidget(widgetBuilder: any, _flowAPI: any, editorSDK: any) {
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: _flowAPI.translations.t('pagesPanel.nftPages.manageItems.button'),
      onClick: (_event: any) => {
        editorSDK.editor.openDashboardPanel('', {url: 'crypto/nft', closeOtherPanels: false});
      },
    })
    .set('mainAction2', {
      behavior: 'DEFAULT1',
    });
}

export const createManifest = (
  appManifestBuilder: AppManifestBuilder,
  _flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK
) => {
  const galleryWidgetId = '3ef6158e-70cd-443e-b4a2-dc61e5f18d3b';
  const assetPageWidgetId = '6336e568-2633-4908-8282-645a5f230b31';

  const manifest = appManifestBuilder
    .configurePages((pagesBuilder) => configurePages(pagesBuilder, _flowAPI))
    .configurePagesTab((pagesTabBuilder) => configurePagesTab(pagesTabBuilder, _flowAPI, editorSDK))
    .configureWidget(galleryWidgetId, (widgetBuilder) => configureWidget(widgetBuilder, _flowAPI, editorSDK))
    .configureWidget(assetPageWidgetId, (widgetBuilder) => configureWidget(widgetBuilder, _flowAPI, editorSDK))
    .build();
  enrichManifestWithGallerySettings(manifest, _flowAPI);
  return manifest;
};
