import {installAppPages, setPagesStates} from './editor/editor.controller';
import {EditorScriptFlowAPI, GetAppManifestFn, EditorReadyFn} from '@wix/yoshi-flow-editor';
import {createManifest} from './editor/manifestGenerator';

// need to bind flowAPI for editor manifest
let _flowAPI: EditorScriptFlowAPI;

export const editorReady: EditorReadyFn = async (editorSDK, token, {firstInstall}, flowAPI) => {
  _flowAPI = flowAPI;
  if (firstInstall) {
    await installAppPages(editorSDK, token);
  }
  await setPagesStates(editorSDK);
};

export const getAppManifest: GetAppManifestFn = ({appManifestBuilder}, editorSDK) => {
  return createManifest(appManifestBuilder, _flowAPI, editorSDK);
};
