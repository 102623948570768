import {FlowEditorSDK} from '@wix/yoshi-flow-editor';

const assetPageId = 'Asset Page';
const galleryId = 'Gallery';

export async function setPagesStates(editorSDK: FlowEditorSDK) {
  const pagesData = await editorSDK.pages.getApplicationPages('');
  const galleryPage = pagesData.filter((p) => p.tpaPageId === galleryId);

  await editorSDK.document.pages.setState('token', {
    state: {
      Gallery: [{id: galleryPage[0].id!}],
    },
  });
}

export async function installAppPages(editorSDK: FlowEditorSDK, token: string) {
  const applicationId = await editorSDK.info.getAppDefinitionId(token);

  // install asset page manually
  await editorSDK.tpa.add.component(token, {
    appDefinitionId: applicationId,
    managingAppDefId: applicationId,
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    page: {
      shouldNavigate: false,
      pageId: assetPageId,
    },
  });

  // gallery page should be installed automatically by dev cetner
  // yet, it's managingAppDefId needs to be set manually

  const allPages = await editorSDK.pages.getApplicationPages('', {includeUnmanaged: true});
  const galleryPageId = allPages.find((a) => a.tpaPageId === galleryId)?.id;
  await editorSDK.pages.data.update('', {
    pageRef: {id: galleryPageId!},
    data: {managingAppDefId: applicationId, title: 'NFT Gallery'},
  });
}
